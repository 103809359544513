.ourMissionSection::before {
    content: "";
    padding-top: 80px;
}

.ourMissionSection {
    width: auto;
    height: 1301px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%),
        url(/images/landing-pages/about/our-mission/bg.png);
    background-color: lightgray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 992px) {
        height: 677px;
    }
}