.whoCohartIsForSection::before {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 73.18%), url(/images/landing-pages/about/who/bg.png) lightgray 50% / cover no-repeat;
}

.whoCohartIsForSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}